$(window).resize(resizeSickyHeader);

$(window).scroll(function(){
  resizeSickyHeader();
  $('.sticky-header').each(function(){
    var panelTopPos = $(this).parent('table').parent('.panel').offset().top - $(window).scrollTop();
    var panelHeight = $(this).parent('table').parent('.panel').height();
    if (panelTopPos <= 64 && panelTopPos >= -1*(panelHeight-64)) {
      $(this).show();
    } else {
      $(this).hide();
    }
  });

});

function resizeSickyHeader() {
  $('.sticky-header').each(function(){
    var panelWidth = $(this).parent('table').parent('.panel').width();
    $(this).width(panelWidth);

    var tdWidths = [];
    $(this).parent('table').children('tbody').children('tr').children('td').each(function(){
      tdWidths.push($(this).width());
    });

    var i = 0;
    $(this).children('tr').children('td').each(function(){
      $(this).width(tdWidths[i]);
      i++;
    });
  });
}
